<template>
  <main>
    <v-card>
      <v-toolbar color="white" flat>
        <v-toolbar-title class="grey--text font-weight-bold">
          NOVEDADES 2
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <span class="green--text">Descripción novedad</span>
            <v-textarea
              dense
              solo
              v-model="news.description"
              :error-messages="validationErrors.description"
            ></v-textarea>
          </v-col>
          <v-col cols="4">
            <span class="green--text">Asignar alerta</span>
            <v-menu
              v-model="datePickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                 v-model="news.alert_date" dense solo v-on="on">
                </v-text-field>
              </template>
              <v-date-picker
                :min="new Date().toISOString().substr(0, 10)"
                v-model="news.alert_date"
                locale="es-co"
                solo
                @input="datePickerMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <evidences
              ref="evidencesComponent"
              :object-id="storeId"
              module="ordinary_justice"
              content-type="News"
            ></evidences>
          </v-col>
        </v-row>
        <!-- save button -->
        <div class="text-center mt-2">
          <v-btn small class="mx-2" color="primary" rounded @click="save()">
            GUARDAR NOVEDAD <v-icon class="ml-1">mdi-content-save</v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mt-2">
      <v-toolbar color="white" flat>
        <v-toolbar-title class="grey--text font-weight-bold">
          HISTORIAL DE NOVEDADES
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="newsList" show-expand>
              <template v-slot:item.alert_date="{ item }">
                <div v-if="item.alert_date">
                  {{ item.alert_date | formatDate }}
                </div>
                <div v-else>--</div>
              </template>
              <template v-slot:item.created_at="{ item }">
                <span>{{ item.created_at | formatDate }}</span>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pt-1">
                  <v-row>
                    <v-col cols="4" v-for="(evidence, i) in item.evidences" :key="i">
                      <evidence-item :evidence-item="evidence"></evidence-item>
                    </v-col>
                  </v-row>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </main>
</template>

<script>
import { listNews, storeNews } from "@/services/OrdinaryJustice";
import { maxLength, required } from "vuelidate/lib/validators";
import Evidences from "@/components/commonComponents/Evidences";
import EvidenceItem from "@/components/commonComponents/EvidenceItem";

export default {
  name: "OrdinaryJusticeNews",
  components: { Evidences, EvidenceItem },
  props: ["caseId"],
  data() {
    return {
      datePickerMenu: false,
      newsList: [],
      news: {
        alert_date: new Date().toISOString().substring(0, 10),
        description: null,
      },
      storeId: null,
      headers: [
        { text: "Fecha", value: "created_at" },
        { text: "Descripción", value: "description" },
        { text: "Alerta", value: "alert_date" },
      ],
    };
  },
  watch: {
    caseId() {
      this.list();
    },
  },
  async created() {
    this.list();
  },
  validations() {
    return {
      news: {
        description: {
          required,
          maxLength: maxLength(4000),
        },
      },
    };
  },
  methods: {
    async save() {
      this.$v.news.$touch();
      if (this.$v.news.$invalid) {
        return;
      }
      try {
        this.showLoader();
        const response = await storeNews({
          case_id: this.caseId,
          alert_date: this.news.alert_date,
          description: this.news.description,
        });
        this.storeId = response.data.news.id;
        await this.$nextTick();
        await this.$refs.evidencesComponent.upload();
        this.hideLoader();
        this.news = {
          alert_date: null,
          description: null,
        };
        this.list();
      } catch (e) {
        this.hideLoader();
        console.log(e);
      }
    },
    list() {
      this.showLoader();
      listNews(this.caseId).then((response) => {
        this.newsList = response.data;
        this.hideLoader();
      });
    },
  },
  computed: {
    ordinaryJusticeReference() {
      return this.$store.state.ordinaryJusticeReference;
    },
    validationErrors() {
      return {
        description: [
          ...[
            !this.$v.news.description.required ? "este campo es requerido" : "",
          ].filter((x) => x != ""),
          ...[
            !this.$v.news.description.maxLength
              ? "la longitud maxima es de 4000 caracteres"
              : "",
          ].filter((x) => x != ""),
        ],
      };
    },
  },
};
</script>

<style scoped></style>
