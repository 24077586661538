<template>
  <v-container fluid>
    <!-- v-card with v tabs vertical -->
    <v-card>
      <v-tabs vertical>
        <v-tab>
          Información del caso
          <v-icon right> mdi-chevron-right </v-icon>
        </v-tab>
        <v-tab v-if="mode === 'edit'">
          Estado
          <v-icon right> mdi-chevron-right </v-icon>
        </v-tab>
        <v-tab
          v-if="mode === 'edit' && getFormFormat === formFormats.FORMAT_TWO"
        >
          Seguimiento de audiencias
          <v-icon right> mdi-chevron-right </v-icon>
        </v-tab>
        <v-tab v-if="mode === 'edit'">
          Novedades
          <v-icon>mdi-chevron-right</v-icon>
        </v-tab>
        <v-tab-item>
          <v-card flat outlined>
            <case-form
              :type="type"
              :mode="mode"
              ref="caseFormComponent"
            ></case-form>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="mode === 'edit'">
          <v-card flat outlined>
            <case-state
              :case-id="this.id"
              ref="caseStateComponent"
              key="caseStateComponent"
            ></case-state>
          </v-card>
        </v-tab-item>
        <v-tab-item
          v-if="mode === 'edit' && getFormFormat === formFormats.FORMAT_TWO"
        >
          <v-card flat outlined>
            <ordinary-justice-audience-tracking
              :case-id="this.id"
              ref="audienceTrackingComponent"
              key="audienceTrackingComponent"
            ></ordinary-justice-audience-tracking>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="mode === 'edit'">
          <v-card flat outlined>
            <news
              :case-id="this.id"
              ref="newsComponent"
              key="newsComponent"
            ></news>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import CaseForm from "@/views/OrdinaryJustice/Components/CaseForm";
import CaseState from "@/views/OrdinaryJustice/Components/CaseState";
import { showCase } from "@/services/OrdinaryJustice";
import News from "@/views/OrdinaryJustice/Components/News";
import OrdinaryJusticeAudienceTracking from "@/views/OrdinaryJustice/Components/AudienceTracking";

export default {
  name: "OrdinaryJusticeCreate",
  components: { OrdinaryJusticeAudienceTracking, News, CaseForm, CaseState },
  data() {
    return {
      type: "",
      mode: "",
      id: "",
      caseInfo: {},
      formFormats: {
        FORMAT_ONE: "FORMAT_ONE",
        FORMAT_TWO: "FORMAT_TWO",
      },
    };
  },
  async created() {
    this.type = this.$route.params.type;
    this.mode = this.$route.params.mode;
    await this.$store.dispatch('getOrdinaryJusticeReferenceValue');
    if (this.mode === "edit") {
      this.id = this.$route.params.id;
      const response = await showCase(this.id);
      this.caseInfo = response.data;
      await this.$nextTick();
      this.$refs.caseFormComponent.setData(this.caseInfo);
    }
    await this.$store.dispatch("getOrdinaryJusticeReferenceValue");
  },
  computed: {
    getFormFormat() {
      const groupOne = ["HABEAS_CORPUS", "CONSTITUTIONAL"];
      const secondGroup = ["PENAL", "LIBERATION", "PROTEST"];

      if (groupOne.includes(this.type)) {
        return "FORMAT_ONE";
      } else if (secondGroup.includes(this.type)) {
        return "FORMAT_TWO";
      }

      return "";
    },
  },
};
</script>

<style scoped></style>
