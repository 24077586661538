<template>
  <main>
    <v-card>
      <v-toolbar color="white" flat>
        <v-toolbar-title class="grey--text font-weight-bold">
          HISTORIAL DE ESTADOS
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <span class="green--text">Estado</span>
            <v-select
              dense
              solo
              :items="statusForm"
              item-value="id"
              item-text="name"
              v-model="newState.state_id"
              :error-messages="validationErrors.state_id"
            ></v-select>
          </v-col>
          <v-col cols="8">
            <span class="green--text">Descripción</span>
            <v-textarea
              dense
              solo
              v-model="newState.description"
              :error-messages="validationErrors.description"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <evidences
              ref="evidencesComponent"
              :object-id="storeId"
              module="ordinary_justice"
              content-type="CaseState"
            ></evidences>
          </v-col>
        </v-row>
        <!-- save button -->
        <div class="text-center mt-2">
          <v-btn small class="mx-2" color="primary" rounded @click="save()" v-if="$can('handle_ordinary_justice')">
            ACTUALIZAR ESTADO <v-icon class="ml-1">mdi-content-save</v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mt-2">
      <v-toolbar color="white" flat>
        <v-toolbar-title class="grey--text font-weight-bold">
          HISTORIAL DE ESTADOS
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="states">
              <template v-slot:item.created_at="{ item }">
                <span>{{ item.created_at | formatDate }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </main>
</template>

<script>
import { listCaseStates, storeCaseState } from "@/services/OrdinaryJustice";
import { maxLength, required } from "vuelidate/lib/validators";
import Evidences from "@/components/commonComponents/Evidences";

export default {
  name: "CaseState.vue",
  components: { Evidences },
  props: ["caseId"],
  data() {
    return {
      states: [],
      newState: {
        state_id: null,
        description: null,
      },
      storeId: null,
      headers: [
        { text: "Fecha", value: "created_at" },
        { text: "Estado", value: "state" },
        { text: "Descripción", value: "description" },
      ],
    };
  },
  watch: {
    caseId() {
      this.list();
    },
  },
  created() {
    this.list();
  },
  validations() {
    return {
      newState: {
        state_id: {
          required,
        },
        description: {
          required,
          maxLength: maxLength(4000),
        },
      },
    };
  },
  methods: {
    async save() {
      this.$v.newState.$touch();
      if (this.$v.newState.$invalid) {
        return;
      }
      try {
        this.showLoader();
        const response = await storeCaseState({
          case_id: this.caseId,
          state_id: this.newState.state_id,
          description: this.newState.description,
        });
        this.storeId = response.data.case_state.id;
        await this.$nextTick();
        await this.$refs.evidencesComponent.upload();
        this.hideLoader();
        this.list();
      } catch (e) {
        this.hideLoader();
        console.log(e);
      }
    },
    list() {
      this.showLoader();
      listCaseStates(this.caseId).then((response) => {
        this.states = response.data;
        this.hideLoader();
      });
    },
  },
  computed: {
    ordinaryJusticeReference() {
      return this.$store.state.ordinaryJusticeReference;
    },
    statusForm(){
      let case_types = this.$store.state.ordinaryJusticeReference.case_types;
      let case_type_form = case_types.find((item) => item.code == this.$route.params.type)
      return this.ordinaryJusticeReference.states.filter((item) => item.type_form == case_type_form?.type_form);
    },
    validationErrors() {
      return {
        state_id: [
          ...[
            !this.$v.newState.state_id.required
              ? "este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        description: [
          ...[
            !this.$v.newState.description.required
              ? "este campo es requerido"
              : "",
          ].filter((x) => x != ""),
          ...[
            !this.$v.newState.description.maxLength
              ? "la longitud maxima es de 4000 caracteres"
              : "",
          ].filter((x) => x != ""),
        ],
      };
    },
  },
};
</script>

<style scoped></style>
