<template>
  <main>
    <v-card>
      <v-toolbar color="white" flat>
        <v-toolbar-title class="grey--text font-weight-bold">
          INFORMACIÓN GENERAL
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- user select -->
        <v-select
          class="mt-6"
          :items="users"
          item-text="fullName"
          item-value="id"
          label="Usuario"
          v-model="ordinaryJusticeCase.user_id"
          solo
          dense
          :error-messages="validationErrors.user_id"
        ></v-select>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Fecha de recepción</span>
            <v-menu
              v-model="datePickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="ordinaryJusticeCase.date"
                  dense
                  solo
                  v-on="on"
                  :error-messages="validationErrors.date"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="ordinaryJusticeCase.date"
                :max="new Date().toISOString().substr(0, 10)"
                locale="es-co"
                solo
                @input="datePickerMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="4"
            sm="12"
            v-if="formFormats.FORMAT_TWO === getFormFormat"
          >
            <span class="green--text">Juzgado</span>
            <v-text-field
              v-model="ordinaryJusticeCase.court"
              dense
              solo
              :error-messages="validationErrors.court"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="4"
            sm="12"
            v-if="formFormats.FORMAT_TWO === getFormFormat"
          >
            <span class="green--text">Radicado</span>
            <v-text-field
              v-model="ordinaryJusticeCase.register_number"
              dense
              solo
              :error-messages="validationErrors.register_number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Departamento de recepción</span>
            <v-autocomplete
              v-model="ordinaryJusticeCase.department_id"
              :items="dataSource.data.Departamento"
              dense
              item-text="descripcion"
              item-value="departamento_id"
              label="Departamento de recepcion"
              solo
              :error-messages="validationErrors.department_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Municipio de recepción</span>
            <v-autocomplete
              v-model="ordinaryJusticeCase.municipality_id"
              :items="getMunicipalities(ordinaryJusticeCase.department_id)"
              dense
              item-text="descripcion"
              item-value="municipio_id"
              label="Municipio de recepcion"
              solo
              :error-messages="validationErrors.municipality_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Territorio de recepción</span>
            <v-autocomplete
              v-model="ordinaryJusticeCase.territory_id"
              :items="getTerritories(ordinaryJusticeCase.municipality_id)"
              dense
              item-text="descripcion"
              item-value="territorio_id"
              label="Territorio de recepcion"
              solo
              :error-messages="validationErrors.territory_id"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="4"
            sm="12"
            v-if="formFormats.FORMAT_ONE === getFormFormat"
          >
            <span class="green--text">Derecho fundamental protegido</span>
            <v-autocomplete
              v-model="ordinaryJusticeCase.fundamental_right_id"
              :items="ordinaryJusticeReference.fundamental_rights"
              dense
              item-text="name"
              item-value="id"
              label="Derecho fundamental protegido"
              solo
              :error-messages="validationErrors.fundamental_right_id"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="getFormFormat === formFormats.FORMAT_ONE">
      <v-toolbar color="white" flat>
        <v-toolbar-title class="grey--text font-weight-bold">
          INFORMACIÓN DEL ACCIONANTE
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Tipo de documento</span>
            <v-autocomplete
              v-model="ordinaryJusticeCase.trigger.document_type_id"
              :items="generalReference.documentTypes"
              dense
              item-text="name"
              item-value="id"
              label="Tipo de documento"
              solo
              :error-messages="validationErrors.trigger.document_type_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Número de documento</span>
            <v-text-field
              v-model="ordinaryJusticeCase.trigger.document_number"
              dense
              label="Número de documento"
              solo
              :error-messages="validationErrors.trigger.document_number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text"> {{ !isDocumentNaturalPersonTrigger ? "Nombre/Razón Social" : "Primer nombre" }}</span>
            <v-text-field
              v-model="ordinaryJusticeCase.trigger.name"
              dense
              label="Nombre"
              solo
              :error-messages="validationErrors.trigger.name"
            ></v-text-field>
          </v-col>
          <v-col v-show="ordinaryJusticeCase.trigger.document_type_id != 8 && ordinaryJusticeCase.trigger.document_type_id != 9" cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Segundo nombre</span>
            <v-text-field
              v-model="ordinaryJusticeCase.trigger.second_name"
              dense
              label="Segundo nombre"
              solo
              :error-messages="validationErrors.trigger.second_name"
            ></v-text-field>
          </v-col>
          <v-col  v-show="ordinaryJusticeCase.trigger.document_type_id != 8 && ordinaryJusticeCase.trigger.document_type_id != 9" cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Primer apellido</span>
            <v-text-field
              v-model="ordinaryJusticeCase.trigger.lastname"
              dense
              label="Primer apellido"
              solo
              :error-messages="validationErrors.trigger.lastname"
            ></v-text-field>
          </v-col>
          <v-col  v-show="ordinaryJusticeCase.trigger.document_type_id != 8 && ordinaryJusticeCase.trigger.document_type_id != 9" cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Segundo apellido</span>
            <v-text-field
              v-model="ordinaryJusticeCase.trigger.second_lastname"
              dense
              label="Segundo apellido"
              solo
              :error-messages="validationErrors.trigger.second_lastname"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Sexo</span>
            <v-autocomplete
              v-model="ordinaryJusticeCase.trigger.genre"
              :items="[
                { id: 'M', name: 'Masculino' },
                { id: 'F', name: 'Femenino' },
              ]"
              dense
              item-text="name"
              item-value="id"
              label="Género"
              solo
              :error-messages="validationErrors.trigger.genre"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Pertencia étnica</span>
            <v-autocomplete
              v-model="ordinaryJusticeCase.trigger.ethnicity_id"
              :items="generalReference.ethnicity"
              dense
              item-text="name"
              item-value="id"
              label="Pertenencia étnica"
              solo
              :error-messages="validationErrors.trigger.ethnicity_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Edad</span>
            <v-text-field
              v-model="ordinaryJusticeCase.trigger.age"
              dense
              solo
              type="number"
              :error-messages="validationErrors.trigger.age"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Correo electrónico</span>
            <v-text-field
              v-model="ordinaryJusticeCase.trigger.email"
              dense
              label="Correo electrónico"
              solo
              :error-messages="validationErrors.trigger.email"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Teléfono</span>
            <v-text-field
              v-model="ordinaryJusticeCase.trigger.phone_number"
              dense
              label="Teléfono"
              solo
              :error-messages="validationErrors.trigger.phone_number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Departamento</span>
            <v-autocomplete
              v-model="ordinaryJusticeCase.trigger.department_id"
              :items="dataSource.data.Departamento"
              dense
              item-text="descripcion"
              item-value="departamento_id"
              label="Departamento"
              solo
              :error-messages="validationErrors.trigger.department_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Municipio</span>
            <v-autocomplete
              v-model="ordinaryJusticeCase.trigger.municipality_id"
              :items="getMunicipalities(ordinaryJusticeCase.trigger.department_id)"
              dense
              item-text="descripcion"
              item-value="municipio_id"
              label="Municipio"
              solo
              :error-messages="validationErrors.trigger.municipality_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Territorio</span>
            <v-autocomplete
              v-model="ordinaryJusticeCase.trigger.territory_id"
              :items="getTerritories(ordinaryJusticeCase.trigger.municipality_id)"
              dense
              item-text="descripcion"
              item-value="territorio_id"
              label="Territorio"
              solo
              :error-messages="validationErrors.trigger.territory_id"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="getFormFormat === formFormats.FORMAT_ONE">
      <v-toolbar color="white" flat>
        <v-toolbar-title class="grey--text font-weight-bold">
          INFORMACIÓN DEL ACCIONADO
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Tipo de persona</span>
            <v-autocomplete
              v-model="ordinaryJusticeCase.defendant.person_type_id"
              :items="generalReference.personTypes"
              dense
              item-text="name"
              item-value="id"
              label="Tipo de persona"
              solo
              :error-messages="validationErrors.defendant.person_type_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Tipo de documento</span>
            <v-autocomplete
              v-model="ordinaryJusticeCase.defendant.document_type_id"
              :items="documentTypeFilter"
              dense
              item-text="name"
              item-value="id"
              label="Tipo de documento"
              solo
              :error-messages="validationErrors.defendant.document_type_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Número de documento</span>
            <v-text-field
              v-model="ordinaryJusticeCase.defendant.document_number"
              dense
              label="Número de documento"
              solo
              :error-messages="validationErrors.defendant.document_number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">{{!isDocumentNaturalPersonDefendant ? "Nombre/Razón Social" : "Primer nombre"}}</span>
            <v-text-field
              v-model="ordinaryJusticeCase.defendant.name"
              dense
              label="Nombre"
              solo
              :error-messages="validationErrors.defendant.name"
            ></v-text-field>
          </v-col>
          <v-col v-show="isDocumentNaturalPersonDefendant" cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Segundo nombre</span>
            <v-text-field
              v-model="ordinaryJusticeCase.defendant.second_name"
              dense
              label="Segundo nombre"
              solo
              :error-messages="validationErrors.defendant.second_name"
            ></v-text-field>
          </v-col>
          <v-col  v-show="isDocumentNaturalPersonDefendant" cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Primer apellido</span>
            <v-text-field
              v-model="ordinaryJusticeCase.defendant.lastname"
              dense
              label="Primer apellido"
              solo
              :error-messages="validationErrors.defendant.lastname"
            ></v-text-field>
          </v-col>
          <v-col  v-show="isDocumentNaturalPersonDefendant" cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Segundo apellido</span>
            <v-text-field
              v-model="ordinaryJusticeCase.defendant.second_lastname"
              dense
              label="Segundo apellido"
              solo
              :error-messages="validationErrors.defendant.second_lastname"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Departamento</span>
            <v-autocomplete
              v-model="ordinaryJusticeCase.defendant.department_id"
              :items="dataSource.data.Departamento"
              dense
              item-text="descripcion"
              item-value="departamento_id"
              label="Departamento"
              solo
              :error-messages="validationErrors.defendant.department_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Municipio</span>
            <v-autocomplete
              v-model="ordinaryJusticeCase.defendant.municipality_id"
              :items="getMunicipalities(ordinaryJusticeCase.defendant.department_id)"
              dense
              item-text="descripcion"
              item-value="municipio_id"
              label="Municipio"
              solo
              :error-messages="validationErrors.defendant.municipality_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Territorio</span>
            <v-autocomplete
              v-model="ordinaryJusticeCase.defendant.territory_id"
              :items="getTerritories(ordinaryJusticeCase.defendant.municipality_id)"
              dense
              item-text="descripcion"
              item-value="territorio_id"
              label="Territorio"
              solo
              :error-messages="validationErrors.defendant.territory_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Dirección</span>
            <v-text-field
              v-model="ordinaryJusticeCase.defendant.address"
              dense
              label="Dirección"
              solo
              :error-messages="validationErrors.defendant.address"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Teléfono</span>
            <v-text-field
              v-model="ordinaryJusticeCase.defendant.phone_number"
              dense
              label="Teléfono"
              solo
              :error-messages="validationErrors.defendant.phone_number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <span class="green--text">Correo electrónico</span>
            <v-text-field
              v-model="ordinaryJusticeCase.defendant.email"
              dense
              label="Correo electrónico"
              solo
              :error-messages="validationErrors.defendant.email"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-toolbar color="white" flat>
        <v-toolbar-title class="grey--text font-weight-bold">
          DESCRIPCIÓN DE LOS HECHOS
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12">
            <span class="green--text">Descripción</span>
            <v-textarea
              v-model="ordinaryJusticeCase.description"
              :error-messages="validationErrors.description"
              dense
              solo
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-toolbar color="white" flat>
        <v-toolbar-title class="grey--text font-weight-bold">
          DOCUMENTOS ADJUNTOS
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12">
            <evidences
              content-type="OrdinaryJusticeCase"
              module="ordinary_justice"
              :object-id="storedId"
              ref="evidencesComponent"
            ></evidences>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--save button-->
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12">
            <div class="text-center mt-2">
              <v-btn small class="mx-2" color="primary" rounded @click="save()" v-if="$can('handle_ordinary_justice')">
                GUARDAR <v-icon class="ml-1">mdi-content-save</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </main>
</template>

<script>
import { storeNewCase, updateCase } from "@/services/OrdinaryJustice";
import Swal from "sweetalert2";
import { usersList } from "@/services/usersService";
import { required, email, numeric, alpha, maxLength } from "vuelidate/lib/validators";
import { alphaNumericAndSpecialCharacters } from "@/utils/customValidators";
import Evidences from "@/components/commonComponents/Evidences";
export default {
  name: "CaseForm",
  components: { Evidences },
  props: ["type", "mode"],
  data() {
    return {
      storedId: null,
      datePickerMenu: false,
      users: [],
      ordinaryJusticeCase: {
        id: "",
        user_id: "",
        case_type_id: "",
        date: new Date().toISOString().substr(0, 10),
        department_id: null,
        municipality_id: null,
        territory_id: null,
        fundamental_right_id: null,
        court: "",
        register_number: "",
        trigger: {
          document_type_id: null,
          document_number: null,
          name: null,
          second_name: null,
          lastname: null,
          second_lastname: null,
          phone_number: null,
          genre: null,
          ethnicity_id: null,
          age: null,
          email: null,
          address: null,
          department_id: null,
          municipality_id: null,
          territory_id: null,
          study_level_id: null,
          business_name: null,
        },
        defendant: {
          person_type_id: null,
          document_type_id: null,
          document_number: null,
          name: null,
          second_name: null,
          lastname: null,
          second_lastname: null,
          phone_number: null,
          department_id: null,
          municipality_id: null,
          territory_id: null,
          address: null,
          email: null,
          business_name: null,
        },
      },
      formFormats: {
        FORMAT_ONE: "FORMAT_ONE",
        FORMAT_TWO: "FORMAT_TWO",
      },
    };
  },
  methods: {
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        this.showLoader();
        this.ordinaryJusticeCase.case_type_id = this.caseTypes.find(
          (item) => item.code === this.type
        ).id;
        const toSaveCase = { ...this.ordinaryJusticeCase };
        toSaveCase.trigger.lastname = this.getLastName("trigger", toSaveCase.trigger.lastname)
        toSaveCase.defendant.lastname = this.getLastName("defendant", toSaveCase.trigger.lastname)
        console.log(toSaveCase);
        if (this.getFormFormat === this.formFormats.FORMAT_TWO) {
          delete toSaveCase["trigger"];
          delete toSaveCase["defendant"];
        }
        if (this.mode === "edit") {
          const response = await updateCase(
            this.ordinaryJusticeCase.id,
            toSaveCase
          );
          this.storedId = response.data.case.id;
        } else {
          const response = await storeNewCase(toSaveCase);
          this.storedId = response.data.case.id;
        }

        await this.$nextTick();
        await this.$refs.evidencesComponent.upload();
        this.hideLoader();
        await Swal.fire({
          title: "Registro guardado correctamente",
          text: "",
          icon: "success",
          confirmButtonText: "Aceptar",
        });

        this.$router.push({ name: "ordinary-justice.cases" });
      } catch (e) {
        this.hideLoader();
        console.log(e);
        Swal.fire("Error", "Ha ocurrido un error", "error");
      }
    },
    getLastName(type, value){
      let typeDoc = this.ordinaryJusticeCase[type]

      if(typeDoc.document_type_id == 8 || typeDoc.document_type_id == 9)
      {
        return "NOTIENE";
      }
      else
      {
        return value;
      }
    },
    async setData(data) {
      this.ordinaryJusticeCase = {
        id: data.id,
        user_id: data.user.id,
        case_type_id: data.case_type.id,
        date: data.date,
        department_id: data.department,
        municipality_id: data.municipality,
        territory_id: data.territory.id,
        fundamental_right_id: data.fundamental_right,
        court: data.court,
        register_number: data.register_number,
        description: data.description,
        trigger: {},
        defendant: {},
      };

      if (this.getFormFormat === this.formFormats.FORMAT_ONE) {
        this.ordinaryJusticeCase.trigger = {
          document_type_id: data.trigger.document_type,
          document_number: data.trigger.document_number,
          name: data.trigger.name,
          second_name: data.trigger.second_name,
          lastname: this.getLastName("trigger", data.trigger.lastname),
          second_lastname: data.trigger.second_lastname,
          phone_number: data.trigger.phone_number,
          genre: data.trigger.genre,
          ethnicity_id: data.trigger.ethnicity,
          age: data.trigger.age,
          email: data.trigger.email,
          address: data.trigger.address,
          department_id: data.trigger.department,
          municipality_id: data.trigger.municipality,
          territory_id: data.trigger.territory,
          study_level_id: null,
        };

        this.ordinaryJusticeCase.defendant = {
          person_type_id: data.defendant.person_type,
          document_type_id: data.defendant.document_type,
          document_number: data.defendant.document_number,
          name: data.defendant.name,
          second_name: data.defendant.second_name,
          lastname: this.getLastName("defendant", data.defendant.lastname),
          second_lastname: data.defendant.second_lastname,
          phone_number: data.defendant.phone_number,
          department_id: data.defendant.department,
          municipality_id: data.defendant.municipality,
          territory_id: data.defendant.territory,
          address: data.defendant.address,
          email: data.defendant.email,
        };
      }

      this.storedId = data.id;
      await this.$nextTick();
      await this.$refs.evidencesComponent.list();
    },
    getMunicipalities(deparment_id){
      return this.$store.state.dataSource.data.Municipio.filter(
          (item) => item.departamento === deparment_id
      );
    },
    //get territories by municipality
    getTerritories(municipality_id){
      const territories = this.$store.state.dataSource.data.Municipio.find(
          (item) => item.municipio_id === municipality_id
      )?.municipio_territoriomunicipio || [];

      return this.$store.state.dataSource.data.Territorio.filter(
          (item) => territories.map((item) => item.territorio_id).includes(item.territorio_id)
      );
    },
  },
  async created() {
    const usersResponse = await usersList();
    this.users = usersResponse.data.map((x) => {
      return {
        id: x.id,
        fullName: `${x.first_name} ${x.last_name}`,
      };
    });
  },
  validations() {
    if (this.getFormFormat === this.formFormats.FORMAT_ONE) {
      return {
        ordinaryJusticeCase: {
          description: {
            maxLength: maxLength(70)
          },
          user_id: {
            required,
          },
          date: {
            required,
          },
          department_id: {
            required,
          },
          municipality_id: {
            required,
          },
          territory_id: {
            required,
          },
          fundamental_right_id: {
            required,
          },
          trigger: {
            document_type_id: {
              required,
            },
            document_number: {
              required,
              numeric,
            },
            ...this.isDocumentNaturalPersonTrigger && {
              name: {
                required,
                alpha
              },
            },
            ...!this.isDocumentNaturalPersonTrigger && {
              name: {
                required,
                alphaNumericAndSpecialCharacters
              },
            },
            second_name: {
              alpha,
            },
            lastname: {
              //required,
              alpha,
            },
            second_lastname: {
              alpha,
            },
            phone_number: {
              required,
              numeric,
            },
            genre: {
              required,
            },
            ethnicity_id: {
              required,
            },
            age: { required, numeric },
            email: { required, email },
            department_id: { required },
            municipality_id: { required },
            territory_id: { required },
          },
          defendant: {
            person_type_id: {
              required,
            },
            document_type_id: {
              required,
            },
            document_number: {
              required,
              numeric,
            },
            ...this.isDocumentNaturalPersonDefendant && {
              name: {
                required,
                alpha
              },
            },
            ...!this.isDocumentNaturalPersonDefendant && {
              name: {
                required,
                alphaNumericAndSpecialCharacters
              },
            },
            second_name: {
              alpha,
            },
            lastname: {
              //required,
              alpha,
            },
            second_lastname: {
              alpha,
            },
            phone_number: {
              required,
              numeric,
            },
            department_id: {
              required,
            },
            municipality_id: {
              required,
            },
            territory_id: {
              required,
            },
            address: {
              required,
            },
            email: {
              required,
              email,
            },
          },
        },
      };
    } else {
      return {
        ordinaryJusticeCase: {
          description: {
            maxLength: maxLength(70)
          },
          user_id: {
            required,
          },
          date: {
            required,
          },
          department_id: {
            required,
          },
          municipality_id: {
            required,
          },
          territory_id: {
            required,
          },
          court: {
            required,
          },
          register_number: {
            required,
          },

        },
      };
    }
  },
  computed: {
    documentTypeFilter(){
      if(this.ordinaryJusticeCase.defendant.person_type_id == 1)
        return this.generalReference.documentTypes.filter((item) => item.name != "RUT" && item.name != "NIT");
      else
        return this.generalReference.documentTypes.filter((item) => item.name == "RUT" || item.name == "NIT");
    },
    dataSource() {
      return this.$store.state.dataSource;
    },
    ordinaryJusticeReference() {
      return this.$store.state.ordinaryJusticeReference;
    },
    generalReference() {
      return this.$store.state.generalReference;
    },
    caseTypes() {
      return this.$store.state.ordinaryJusticeReference.case_types;
    },
    getFormFormat() {
      const groupOne = ["HABEAS_CORPUS", "CONSTITUTIONAL"];
      const secondGroup = ["PENAL", "LIBERATION", "PROTEST"];

      if (groupOne.includes(this.type)) {
        return "FORMAT_ONE";
      } else if (secondGroup.includes(this.type)) {
        return "FORMAT_TWO";
      }

      return "";
    },
    validationErrors() {
      if (this.getFormFormat === this.formFormats.FORMAT_ONE) {
        return {
          user_id: [
            ...(!this.$v.ordinaryJusticeCase.user_id.required
              ? ["El campo es requerido"]
              : []),
          ],
          description: [
            ...(!this.$v.ordinaryJusticeCase.description.maxLength
              ? ["El campo no debe superar los 70 caracteres"]
              : []),
          ],
          date: [
            ...(!this.$v.ordinaryJusticeCase.date.required
              ? ["El campo es requerido"]
              : []),
          ],
          department_id: [
            ...(!this.$v.ordinaryJusticeCase.department_id.required
              ? ["El campo es requerido"]
              : []),
          ],
          municipality_id: [
            ...(!this.$v.ordinaryJusticeCase.municipality_id.required
              ? ["El campo es requerido"]
              : []),
          ],
          territory_id: [
            ...(!this.$v.ordinaryJusticeCase.territory_id.required
              ? ["El campo es requerido"]
              : []),
          ],
          fundamental_right_id: [
            ...(!this.$v.ordinaryJusticeCase.fundamental_right_id.required
              ? ["El campo es requerido"]
              : []),
          ],
          trigger: {
            document_type_id: [
              ...(!this.$v.ordinaryJusticeCase.trigger.document_type_id.required
                ? ["El campo es requerido"]
                : []),
            ],
            document_number: [
              ...(!this.$v.ordinaryJusticeCase.trigger.document_number.required
                ? ["El campo es requerido"]
                : []),
              ...(!this.$v.ordinaryJusticeCase.trigger.document_number.numeric
                ? ["El campo debe ser numérico"]
                : []),
            ],
            name: [
              ...(!this.$v.ordinaryJusticeCase.trigger.name.required
                ? ["El campo es requerido"]
                : []),
              ...(!this.$v.ordinaryJusticeCase.trigger.name.alpha && this.isDocumentNaturalPersonTrigger
                ? ["El campo debe ser alfabético"]
                : []),
              ...(!this.$v.ordinaryJusticeCase.trigger.name.alphaNumericAndSpecialCharacters && !this.isDocumentNaturalPersonTrigger
                  ? ["El campo contiene caracteres no permitidos"]
                  : []),
            ],
            second_name: [
              ...(!this.$v.ordinaryJusticeCase.trigger.second_name.alpha
                ? ["El campo debe ser alfabético"]
                : []),
            ],
            lastname: [
              /*...(!this.$v.ordinaryJusticeCase.trigger.lastname.required
                ? ["El campo es requerido"]
                : []),*/
              ...(!this.$v.ordinaryJusticeCase.trigger.lastname.alpha
                ? ["El campo debe ser alfabético"]
                : []),
            ],
            second_lastname: [
              ...(!this.$v.ordinaryJusticeCase.trigger.second_lastname.alpha
                ? ["El campo debe ser alfabético"]
                : []),
            ],
            phone_number: [
              ...(!this.$v.ordinaryJusticeCase.trigger.phone_number.required
                ? ["El campo es requerido"]
                : []),
              ...(!this.$v.ordinaryJusticeCase.trigger.phone_number.numeric
                ? ["El campo debe ser numérico"]
                : []),
            ],
            genre: [
              ...(!this.$v.ordinaryJusticeCase.trigger.genre.required
                ? ["El campo es requerido"]
                : []),
            ],
            ethnicity_id: [
              ...(!this.$v.ordinaryJusticeCase.trigger.ethnicity_id.required
                ? ["El campo es requerido"]
                : []),
            ],
            age: [
              ...(!this.$v.ordinaryJusticeCase.trigger.age.required
                ? ["El campo es requerido"]
                : []),
              ...(!this.$v.ordinaryJusticeCase.trigger.age.required
                ? ["El campo debe ser numérico"]
                : []),
            ],
            email: [
              ...(!this.$v.ordinaryJusticeCase.trigger.email.required
                ? ["El campo es requerido"]
                : []),
              ...(!this.$v.ordinaryJusticeCase.trigger.email.email
                ? ["El campo debe ser un correo electrónico"]
                : []),
            ],
            department_id: [
              ...(!this.$v.ordinaryJusticeCase.trigger.department_id.required
                ? ["El campo es requerido"]
                : []),
            ],
            municipality_id: [
              ...(!this.$v.ordinaryJusticeCase.trigger.municipality_id.required
                ? ["El campo es requerido"]
                : []),
            ],
            territory_id: [
              ...(!this.$v.ordinaryJusticeCase.trigger.territory_id.required
                ? ["El campo es requerido"]
                : []),
            ],
          },
          defendant: {
            person_type_id: [
              ...(!this.$v.ordinaryJusticeCase.defendant.person_type_id.required
                ? ["El campo es requerido"]
                : []),
            ],
            document_type_id: [
              ...(!this.$v.ordinaryJusticeCase.defendant.document_type_id
                .required
                ? ["El campo es requerido"]
                : []),
            ],
            document_number: [
              ...(!this.$v.ordinaryJusticeCase.defendant.document_number
                .required
                ? ["El campo es requerido"]
                : []),
              ...(!this.$v.ordinaryJusticeCase.defendant.document_number.numeric
                ? ["El campo debe ser numérico"]
                : []),
            ],
            name: [
              ...(!this.$v.ordinaryJusticeCase.defendant.name.required
                ? ["El campo es requerido"]
                : []),
              ...(!this.$v.ordinaryJusticeCase.defendant.name.alpha && this.isDocumentNaturalPersonDefendant
                ? ["El campo debe ser alfabético"]
                : []),
              ...(!this.$v.ordinaryJusticeCase.defendant.name.alphaNumericAndSpecialCharacters && !this.isDocumentNaturalPersonDefendant
                  ? ["El campo contiene caracteres no permitidos"]
                  : []),
            ],
            second_name: [
              ...(!this.$v.ordinaryJusticeCase.defendant.second_name.alpha
                ? ["El campo debe ser alfabético"]
                : []),
            ],
            lastname: [
              /*...(!this.$v.ordinaryJusticeCase.defendant.lastname.required
                ? ["El campo es requerido"]
                : []),*/
              ...(!this.$v.ordinaryJusticeCase.defendant.lastname.alpha
                ? ["El campo debe ser alfabético"]
                : []),
            ],
            second_lastname: [
              ...(!this.$v.ordinaryJusticeCase.defendant.second_lastname.alpha
                ? ["El campo debe ser alfabético"]
                : []),
            ],
            phone_number: [
              ...(!this.$v.ordinaryJusticeCase.defendant.phone_number.required
                ? ["El campo es requerido"]
                : []),
              ...(!this.$v.ordinaryJusticeCase.defendant.phone_number.numeric
                ? ["El campo debe ser un numero"]
                : []),
            ],
            department_id: [
              ...(!this.$v.ordinaryJusticeCase.defendant.department_id.required
                ? ["El campo es requerido"]
                : []),
            ],
            municipality_id: [
              ...(!this.$v.ordinaryJusticeCase.defendant.municipality_id
                .required
                ? ["El campo es requerido"]
                : []),
            ],
            territory_id: [
              ...(!this.$v.ordinaryJusticeCase.defendant.territory_id.required
                ? ["El campo es requerido"]
                : []),
            ],
            address: [
              ...(!this.$v.ordinaryJusticeCase.defendant.address.required
                ? ["El campo es requerido"]
                : []),
            ],
            email: [
              ...(!this.$v.ordinaryJusticeCase.defendant.email.required
                ? ["El campo es requerido"]
                : []),
              ...(!this.$v.ordinaryJusticeCase.defendant.email.email
                ? ["El campo debe ser un email válido"]
                : []),
            ],
          },
        };
      } else {
        return {
          description: [
            ...(!this.$v.ordinaryJusticeCase.description.maxLength
              ? ["El campo no debe superar los 70 caracteres"]
              : []),
          ],
          user_id: [
            ...(!this.$v.ordinaryJusticeCase.user_id.required
              ? ["El campo es requerido"]
              : []),
          ],
          date: [
            ...(!this.$v.ordinaryJusticeCase.date.required
              ? ["El campo es requerido"]
              : []),
          ],
          department_id: [
            ...(!this.$v.ordinaryJusticeCase.department_id.required
              ? ["El campo es requerido"]
              : []),
          ],
          municipality_id: [
            ...(!this.$v.ordinaryJusticeCase.municipality_id.required
              ? ["El campo es requerido"]
              : []),
          ],
          territory_id: [
            ...(!this.$v.ordinaryJusticeCase.territory_id.required
              ? ["El campo es requerido"]
              : []),
          ],
          court: [
            ...(!this.$v.ordinaryJusticeCase.court.required
              ? ["El campo es requerido"]
              : []),
          ],
          register_number: [
            ...(!this.$v.ordinaryJusticeCase.register_number.required
              ? ["El campo es requerido"]
              : []),
          ],
        };
      }
    },
    isDocumentNaturalPersonTrigger() {
      return (this.ordinaryJusticeCase.trigger.document_type_id !== 8 && this.ordinaryJusticeCase.trigger.document_type_id !== 9);
    },
    isDocumentNaturalPersonDefendant() {
      return (this.ordinaryJusticeCase.defendant.document_type_id !== 8 && this.ordinaryJusticeCase.defendant.document_type_id !== 9);
    },
  },
};
</script>

<style scoped></style>
