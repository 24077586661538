<template>
  <main>
    <v-card>
      <v-toolbar color="white" flat>
        <v-toolbar-title class="grey--text font-weight-bold">
          SEGUIMIENTO DE AUDIENCIAS
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <span class="green--text">Fecha ultima actuación</span>
            <v-menu
              v-model="datePickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="audience_tracking.last_updated_date"
                  dense
                  solo
                  v-on="on"
                  :error-messages="validationErrors.last_updated_date"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="audience_tracking.last_updated_date"
                locale="es-co"
                solo
                @input="datePickerMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- type v-select -->
          <v-col cols="3">
            <span class="green--text">Tipo</span>
            <v-select
              v-model="audience_tracking.audience_type_id"
              :items="ordinaryJusticeReference.audience_types"
              dense
              solo
              item-text="name"
              item-value="id"
              :error-messages="validationErrors.audience_type_id"
            ></v-select>
          </v-col>
          <!-- subtype v-select -->
          <v-col cols="3">
            <span class="green--text">Subtipo</span>
            <v-select
              v-model="audience_tracking.audience_subtype_id"
              :items="subtypes"
              dense
              solo
              item-text="name"
              item-value="id"
              :error-messages="validationErrors.audience_subtype_id"
            ></v-select>
          </v-col>
          <!-- next audience date datepicker -->
          <v-col cols="3">
            <span class="green--text">Fecha proxima audiencia</span>
            <v-menu
              v-model="datePickerMenu2"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="audience_tracking.next_audience_date"
                  dense
                  solo
                  v-on="on"
                  :error-messages="validationErrors.next_audience_date"
                >
                </v-text-field>
              </template>
              <v-date-picker
                :min="new Date().toISOString().substr(0, 10)"
                v-model="audience_tracking.next_audience_date"
                locale="es-co"
                solo
                @input="datePickerMenu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="green--text">Descripción audiencia</span>
            <v-textarea
              dense
              solo
              v-model="audience_tracking.description"
              :error-messages="validationErrors.description"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <evidences
              ref="evidencesComponent"
              :object-id="storeId"
              module="ordinary_justice"
              content-type="AudienceTracking"
            ></evidences>
          </v-col>
        </v-row>
        <!-- save button -->
        <div class="text-center mt-2">
          <v-btn small class="mx-2" color="primary" rounded @click="save()" v-if="$can('handle_ordinary_justice')">
            GUARDAR NOVEDAD <v-icon class="ml-1">mdi-content-save</v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mt-2">
      <v-toolbar color="white" flat>
        <v-toolbar-title class="grey--text font-weight-bold">
          HISTORIAL DE SEGUIMIENTO
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
                :headers="headers"
                :items="audienceList"
                show-expand
            >
              <template v-slot:item.next_audience_date="{ item }">
                <span>{{ item.next_audience_date | formatDate }}</span>
              </template>
              <template v-slot:item.created_at="{ item }">
                <span>{{ item.created_at | formatDate }}</span>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pt-1">
                  <v-row>
                    <v-col cols="4" v-for="(evidence, i) in item.evidences" :key="i">
                      <evidence-item :evidence-item="evidence"></evidence-item>
                    </v-col>
                  </v-row>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </main>
</template>

<script>
import {
  listAudienceTracking,
  storeAudienceTracking,
} from "@/services/OrdinaryJustice";
import { maxLength, required } from "vuelidate/lib/validators";
import Evidences from "@/components/commonComponents/Evidences";
import EvidenceItem from "@/components/commonComponents/EvidenceItem";

export default {
  name: "OrdinaryJusticeAudienceTracking",
  components: {EvidenceItem, Evidences },
  props: ["caseId"],
  data() {
    return {
      datePickerMenu: false,
      datePickerMenu2: false,
      audienceList: [],
      audience_tracking: {
        last_updated_date: null,
        next_audience_date: new Date().toISOString().substring(0, 10),
        audience_type_id: null,
        audience_subtype_id: null,
        description: null,
      },
      storeId: null,
      headers: [
        { text: "Fecha", value: "created_at" },
        { text: "Tipo", value: "audience_type" },
        { text: "Descripcion", value: "description" },
        { text: "Prox Audiencia", value: "next_audience_date" },
      ],
    };
  },
  watch: {
    caseId() {
      this.list();
    },
  },
  created() {
    this.list();
    console.log(this.audienceList);
  },
  validations() {
    return {
      audience_tracking: {
        last_updated_date: {
          required,
        },
        next_audience_date: {
          required,
        },
        audience_type_id: {
          required,
        },
        audience_subtype_id: {
          required,
        },
        description: {
          required,
          maxLength: maxLength(4000),
        },
      },
    };
  },
  methods: {
    async save() {
      this.$v.audience_tracking.$touch();
      if (this.$v.audience_tracking.$invalid) {
        return;
      }
      try {
        this.showLoader();
        const response = await storeAudienceTracking({
          case_id: this.caseId,
          last_updated_date: this.audience_tracking.last_updated_date,
          next_audience_date: this.audience_tracking.next_audience_date,
          audience_type_id: this.audience_tracking.audience_type_id,
          audience_subtype_id: this.audience_tracking.audience_subtype_id,
          description: this.audience_tracking.description,
        });
        this.storeId = response.data.audience_tracking.id;
        await this.$nextTick();
        await this.$refs.evidencesComponent.upload();
        this.hideLoader();
        this.audience_tracking = {
          last_updated_date: null,
          next_audience_date: null,
          audience_type_id: null,
          audience_subtype_id: null,
          description: null,
        };
        this.list();
      } catch (e) {
        this.hideLoader();
        console.log(e);
      }
    },
    list() {
      this.showLoader();
      listAudienceTracking(this.caseId).then((response) => {
        this.audienceList = response.data;
        console.log(this.audienceList);
        this.hideLoader();
      });
    },
  },
  computed: {
    ordinaryJusticeReference() {
      return this.$store.state.ordinaryJusticeReference;
    },
    subtypes() {
      return (
        this.ordinaryJusticeReference.audience_types.find(
          (x) => x.id === this.audience_tracking.audience_type_id
        )?.audience_subtypes || []
      );
    },
    validationErrors() {
      return {
        next_audience_date: [
          ...[
            !this.$v.audience_tracking.next_audience_date.required
              ? "este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        description: [
          ...[
            !this.$v.audience_tracking.description.required
              ? "este campo es requerido"
              : "",
          ].filter((x) => x != ""),
          ...[
            !this.$v.audience_tracking.description.maxLength
              ? "la longitud maxima es de 4000 caracteres"
              : "",
          ].filter((x) => x != ""),
        ],
        last_updated_date: [
          ...[
            !this.$v.audience_tracking.last_updated_date.required
              ? "este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        audience_type_id: [
          ...[
            !this.$v.audience_tracking.audience_type_id.required
              ? "este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        audience_subtype_id: [
          ...[
            !this.$v.audience_tracking.audience_subtype_id.required
              ? "este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
      };
    },
  },
};
</script>

<style scoped></style>
